import React from "react";

import "./VideoModule.scss";

import VideoModule from "./VideoModule";

export default function VideoModuleContainer({ title, video, ...restProps }) {
  const getUploadedVideoUrl = (data) => {
    return data.type === "embed" ? data.embedLink : data.videoMp4.mediaItemUrl;
  };

  return (
    <VideoModule
      title={title}
      video={getUploadedVideoUrl(video)}
      {...restProps}
    />
  );
}
