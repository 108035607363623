import React, { useRef } from "react";

import useComponentSize from "../../hooks/component-size";

import LogoPart0 from "./LogoPart0";
import LogoPart1 from "./LogoPart1";
import LogoPart2 from "./LogoPart2";

import "./TrelleborgLogo.scss";

export default function Logo({ color = "currentColor", ...restProps }) {
  let ref = useRef(null);
  let { width, height } = useComponentSize(ref);
  let containerAspectRatio = width / height;
  let svgHeight = 54;
  let svgWidths = [298, 184, 37, 0];
  let widthIndex = svgWidths.findIndex(
    (width) => width / svgHeight <= containerAspectRatio,
  );

  return (
    <div className="logo logo-trelleborg" ref={ref}>
      <svg
        fill={color}
        className=" logo-trelleborg-1"
        viewBox={`0 0 ${37} ${svgHeight}`}
        {...restProps}
      >
        <LogoPart0 />
      </svg>
      {widthIndex < 2 && (
        <svg
          className=" logo-trelleborg-2"
          fill={color}
          viewBox={`37 0 ${184 - 37} ${svgHeight}`}
          {...restProps}
        >
          <LogoPart1 />
        </svg>
      )}
      {widthIndex < 1 && (
        <svg
          fill={color}
          className=" logo-trelleborg-3"
          viewBox={`184 0 ${298 - 184} ${svgHeight}`}
          {...restProps}
        >
          <LogoPart2 />
        </svg>
      )}
    </div>
  );
}
