import { useIntl } from "gatsby-plugin-intl";
import React, { useEffect, useRef } from "react";

import useID from "../../hooks/id";
import { getTopLevelPages } from "../../utils/pageTree";
import Icon from "../Icon/Icon";
import Link from "../Link/Link";

import MobileNavigationMenuItem from "./MobileNavigationMenuItem";

import "./MobileNavigation.scss";

export default function MobileNavigation({
  allPages,
  shortcuts,
  expandedItems,
  onItemExpand,
  onItemCollapse,
  onCloseClick,
  onLinkClick,
  ...restProps
}) {
  const id = useID();
  const intl = useIntl();
  const ref = useRef();

  useEffect(() => {
    if (!ref.current || !onLinkClick) {
      return;
    }
    const handleClick = (event) => {
      if (event.target.closest("a")) {
        onLinkClick();
      }
    };
    ref.current.addEventListener("click", handleClick);
    return () => {
      ref.current.removeEventListener("click", handleClick);
    };
  }, [ref.current, onLinkClick]);

  return (
    <div
      className="c-flyout"
      id={id("mobile-navigation")}
      role="dialog"
      aria-labelledby={id("mobile-navigation-label")}
      aria-modal="true"
      ref={ref}
      {...restProps}
    >
      <div className="c-flyout__header">
        <h2
          id={id("mobile-navigation-label")}
          className="c-flyout__header-title"
        >
          {intl.formatMessage({ id: "Menu" })}
        </h2>
        <button
          className="c-flyout__close"
          onClick={onCloseClick}
          aria-label={intl.formatMessage({ id: "Close menu" })}
          aria-controls={id("mobile-navigation")}
        >
          <div className="c-flyout__close-inner">
            <Icon name="close-bold" size="16" role="presentation" />
          </div>
          <span className="c-flyout__close-label u-visually-hidden">
            {intl.formatMessage({ id: "Close" })}
          </span>
        </button>
      </div>
      <div className="c-flyout__content">
        <ul id="c-flyout__nav" className="c-flyout__nav">
          {getTopLevelPages(allPages)
            .filter((page) => page.showInMenu)
            .map((page, index) => {
              return (
                <MobileNavigationMenuItem
                  item={page}
                  key={index}
                  expandedItems={expandedItems}
                  allPages={allPages}
                  onItemExpand={onItemExpand}
                  onItemCollapse={onItemCollapse}
                />
              );
            })}
        </ul>
      </div>
      {shortcuts && shortcuts.items && shortcuts.items.length && (
        <div className="c-flyout__help-nav">
          <ul className="c-flyout__help-nav-list">
            {shortcuts.items.map((shortcut, index) => {
              return (
                <li key={index} className="c-flyout__help-nav-list-item">
                  <Link
                    className="c-flyout__help-nav-list-link"
                    to={shortcut.url}
                  >
                    <Icon name={shortcut.icon} />
                    {shortcut.label}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}
