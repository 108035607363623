import { H } from "@jfrk/react-heading-levels";
import cx from "classnames";
import React from "react";

import "./Card.scss";
import Excerpt from "../Excerpt/Excerpt";
import RoundIcon from "../Icon/RoundIcon";
import { FluidImage } from "../Image";
import Link from "../Link/Link";
import Time from "../Time";

export default function CardProject({
  className,
  title,
  date,
  url,
  excerpt,
  content,
  image,
  projectCategories,
  projectStatuses,
  ...restProps
}) {
  const categories = projectCategories?.nodes || [];
  const status = projectStatuses?.nodes[0] || null;
  return (
    <article className={cx("c-card c-card--project c-card--spacing", className)} {...restProps}>
      <div className="c-card__content">
        {title && (
          <div className="c-card__title">
            <div>
              <H className="c-card__title-heading">
                {url ? (
                  <Link to={url} className="c-card__title-heading-link">
                    {title}
                  </Link>
                ) : (
                  title
                )}
              </H>
              {categories.length > 0 && (
                <div className={cx("c-card__meta")}>
                  {categories[0].name}{" "}
                  {categories.length > 1 && `(+${categories.length - 1})`}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {image && (
        <FluidImage
          base64={image.base64}
          src={image.src}
          srcSet={image.srcSet}
          srcWebp={image.srcWebp}
          srcSetWebp={image.srcSetWebp}
          aspectRatio={image.aspectRatio}
          width={image.width}
          height={image.height}
          alt={image.altText}
          className={cx("c-card__image")}
        />
      )}
      {status && (
        <div
          className={cx("c-card__tag", `c-card__tag--${status.options.color}`)}
        >
          {status.options.parent}
        </div>
      )}
    </article>
  );
}
