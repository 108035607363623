import cx from "classnames";
import React from "react";

import ModuleController from "../ModuleController/ModuleController";

export default function ModuleArea({ area, displayGrid = true }) {
  return (
    area &&
    area.map(({ hidden, module, columnWidth }) => {
      if (hidden || !module) {
        return null;
      }
      return (
        <div
          key={module.id}
          className={cx(
            "modularity-module",
            displayGrid && "o-grid-block",
            displayGrid && `o-grid-block--${columnWidth || "inherit"}`,
          )}
        >
          <ModuleController module={module} />
        </div>
      );
    })
  );
}
