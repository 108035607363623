import "./Layout.scss";
import cx from "classnames";
import React from "react";
import Helmet from "react-helmet";

import { useIsMultisite } from "../../hooks/multisite";
import usePageTheme from "../../hooks/page-theme";
import usePageContext from "../../hooks/pageContext";
import Alert from "../Alert";
import Footer from "../Footer";
import FooterShortcuts from "../FooterShortcuts";
import Header from "../Header";

export default function Layout({
  theme,
  header,
  hideSearchForm,
  mainProps: { className: mainClassName, ...mainRestProps } = {},
  children,
  className,
  sliderArea,
  ...restProps
}) {
  const { colorScheme } = usePageTheme();
  const { postId, page } = usePageContext();
  hideSearchForm = true; //!useIsMultisite();

  let pageThemeColor =
    colorScheme && (theme === "default" || !theme)
      ? colorScheme
      : !theme
      ? "default"
      : theme;

  const hero = page ? page.hero : null;

  return (
    <div
      className={cx(`page-theme--${pageThemeColor}`, className)}
      {...restProps}
    >
      <Helmet
        meta={[
          {
            name: "edit-url",
            content: `${process.env.GATSBY_WORDPRESS_URL}/wp-admin/post.php?post=${postId}&action=edit`,
          },
        ]}
      />
      <Header
        hideSearchForm={hideSearchForm}
        hero={hero && hero.heroImage !== null && hero}
        sliderArea={sliderArea}
      >
        {header}
      </Header>
      <main
        id="content"
        className={cx("o-container o-margin-bottom-large", mainClassName)}
        {...mainRestProps}
      >
        {!process.env.GATSBY_DISABLE_ALERT && (
          <Alert className="o-section--full-width" />
        )}
        {children}
      </main>
      <FooterShortcuts />
      <Footer />
    </div>
  );
}
