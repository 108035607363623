import React from "react";

import { useHTMLProcessor } from "../../hooks/htmlProcessor";

import NoticeModule from "./NoticeModule";

export default function NoticeModuleContainer({ description, ...restProps }) {
  const { processContent } = useHTMLProcessor();
  return (
    <NoticeModule {...restProps} description={processContent(description)} />
  );
}
