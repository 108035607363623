import cx from "classnames";
import { useIntl } from "gatsby-plugin-intl";
import React from "react";

import Link from "../Link/Link";

import "./Menu.scss";

export default function Menu({
  menu = { items: [] },
  inverse,
  centered,
  name,
  className,
  ...restProps
}) {
  const intl = useIntl();

  return (
    <nav
      className={cx(
        "c-menu o-container u-hidden-print",
        inverse && "c-menu--inverse",
        centered && "c-menu--centered",
        className,
      )}
      aria-label={intl.formatMessage({ id: name })}
      {...restProps}
    >
      {menu.items && menu.items.length && (
        <ul className="c-menu__list">
          {menu.items
            .filter((item) => item.showInMenu)
            .map((item, index) => {
              return (
                <li key={index} className="c-menu__item">
                  <Link className="c-menu__link" to={item.uri}>
                    {item.label}
                  </Link>
                </li>
              );
            })}
        </ul>
      )}
    </nav>
  );
}
