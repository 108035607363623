import { H } from "@jfrk/react-heading-levels";
import cx from "classnames";
import { useIntl } from "gatsby-plugin-intl";
import React from "react";

import "./NoticeModule.scss";
import Button from "../Button/Button";
import Icon from "../Icon/Icon";

export default function NoticeModule({
  headline,
  description,
  type,
  link,
  hideIcon = false,
  ...restProps
}) {
  const intl = useIntl();
  return (
    <div className={cx("c-notice", type && `c-notice--${type}`)} {...restProps}>
      <div className="c-notice__content">
        <div className="c-notice__headline">
          {!hideIcon && (
            <Icon name={type} className="u-m-r--200 c-notice__icon" />
          )}
          {headline && <H className="c-notice__title">{headline}</H>}
        </div>
        {description && <p className="c-notice__description">{description}</p>}
      </div>
      {link && (
        <Button
          className="c-notice__link"
          title={intl.formatMessage({ id: "More information" })}
          link={link}
          buttonModifier="light"
          iconBefore={true}
          iconBeforeSize="20px"
          iconAfterSize="12px"
          iconAfter={true}
          iconBeforeName="info"
          iconAfterName="arrow-right-1"
          iconModifier="secondary"
        />
      )}
    </div>
  );
}
