import cx from "classnames";
import React from "react";

import Link from "../Link";
import "./CookieConsent.scss";

export default function CookieConsent({
  position,
  approvebutton,
  declinebutton,
  description,
  morelink,
  morelinkurl,
  title,
  onAccept,
  onDeny,
  className,
  ...restProps
}) {
  return (
    <div
      className={cx("c-cookie-consent", position, className)}
      role="region"
      aria-live="polite"
      aria-labelledby="ccTitle"
      aria-describedby="ccDesc"
      {...restProps}
    >
      <div role="document" className="c-cookie-consent__container" tabIndex="0">
        <h2 id="ccTitle" className="c-cookie-consent__title">
          {title}
        </h2>
        <p id="ccDesc" className="c-cookie-consent__description">
          {description}{" "}
          {morelinkurl && morelinkurl.uri ? (
            <Link className={cx("c-cookie-consent__more")} to={morelinkurl.uri}>
              {morelink}
            </Link>
          ) : null}
        </p>

        <div className="c-cookie-consent__button-group">
          {declinebutton && (
            <button
              className="c-cookie-consent__button c-cookie-consent__button--decline"
              type="button"
              onClick={onDeny}
            >
              {declinebutton}
            </button>
          )}
          {approvebutton && (
            <button
              className="c-cookie-consent__button c-cookie-consent__button--approve"
              type="button"
              onClick={onAccept}
            >
              {approvebutton}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
