import React from "react";

import { useHTMLProcessor } from "../../hooks/htmlProcessor";
import ContactCardModule from "../ContactCardModule/ContactCardModule";
import DisturbancesModule from "../DisturbancesModule/DisturbancesModule";
import FactModule from "../FactModule/FactModule";
import FileModule from "../FileModule/FileModule";
import FormModule from "../FormModule";
import GalleryModule from "../GalleryModule/GalleryModule";
import ImageModule from "../ImageModule/ImageModule";
import NoticeModule from "../NoticeModule";
import PostsModule from "../PostsModule";
import ScriptModule from "../ScriptModule";
import TableModule from "../TableModule/TableModule";
import TextModule from "../TextModule/TextModule";
import VideoModule from "../VideoModule";

function normalizeModuleData(module) {
  return { ...module, title: !module.hideTitle && module.title };
}

export default function ModuleController({ module }) {
  const { processContent } = useHTMLProcessor();
  switch (
    (module.postTypeInfo && module.postTypeInfo.name) ||
    module.postType
  ) {
    case "mod-contacts":
      return (
        <ContactCardModule
          title={!module.hideTitle && module.title}
          mode={module.kontakter.displayMode}
          isCompact={module.kontakter.compactMode}
          items={module.kontakter.contacts}
        />
      );
    case "mod-disturbances":
      return (
        <DisturbancesModule
          key={module.id}
          customItems={module.disturbances.otherCards}
          showDisturbances={module.disturbances.showOperationalStatus}
          title={!module.hideTitle && module.title}
        />
      );
    case "mod-gallery":
      return (
        <GalleryModule
          key={module.id}
          images={module.gallery.modGalleryImages}
          title={!module.hideTitle && module.title}
          settings={module.settings}
        />
      );
    case "mod-fileslist":
      return (
        <FileModule
          key={module.id}
          files={module.files.fileList}
          title={!module.hideTitle && module.title}
        />
      );
    case "mod-form":
      return (
        <FormModule
          key={module.id}
          submissionPublicAct={module.form.submissionPublicAct}
          submissionPublicActContent={module.form.submissionPublicActContent}
          gdprComplienceNotice={module.form.gdprComplienceNotice}
          gdprComplienceNoticeContent={module.form.gdprComplienceNoticeContent}
          submissionNotice={module.form.submissionNotice}
          submitButtonText={module.form.submitButtonText}
          nonce={module.nonce}
          id={module.modFormId}
          title={!module.hideTitle && module.title}
          formFields={module.form.formFields}
        />
      );
    case "mod-image":
      if (
        !module.image ||
        !module.image.modImageImage ||
        !module.image.modImageImage.mediaDetails
      ) {
        return null;
      }
      return (
        <ImageModule
          title={!module.hideTitle && module.title}
          linkTo={module.image.modImageLinkUrl}
          caption={
            module.image.modImageCaption
              ? module.image.modImageCaption
              : processContent(module.image.modImageImage.caption)
          }
          credit={module.image.modImageImage.photograph.name}
          base64={module.image.modImageImage.base64}
          src={module.image.modImageImage.src}
          srcSet={module.image.modImageImage.srcSet}
          srcWebp={module.image.modImageImage.src}
          srcSetWebp={module.image.modImageImage.srcSetWebp}
          width={module.image.modImageImage.mediaDetails.width}
          height={module.image.modImageImage.mediaDetails.height}
          alt={module.image.modImageImage.altText}
          aspectRatio={
            module.image.modImageImage.mediaDetails.width /
            module.image.modImageImage.mediaDetails.height
          }
        />
      );
    case "mod-notice":
      return (
        <NoticeModule
          headline={!module.hideTitle && module.title}
          description={module.noticeSettings.noticeText}
          type={module.noticeSettings.noticeType}
          link={module.noticeLink.link && module.noticeLink.link.url}
        />
      );
    case "mod-posts":
      return <PostsModule {...normalizeModuleData(module)} />;
    case "mod-table":
      return (
        <TableModule
          tableData={{
            title: !module.hideTitle && module.title,
            displayAlternatives: module.tableEditor.modTableClasses,
            textSize: module.tableEditor.modTableSize,
            items: JSON.parse(module.tableEditor.modTable),
          }}
        />
      );
    case "mod-text":
      return (
        <TextModule
          title={!module.hideTitle && module.title}
          content={module.content}
          settings={module.textinstLlningar}
          contentMedia={module.contentMedia}
        />
      );
    case "mod-fact":
      return (
        <FactModule
          title={!module.hideTitle && module.title}
          content={module.content}
          contentMedia={module.contentMedia}
        />
      );
    case "mod-video":
      return (
        <VideoModule
          title={!module.hideTitle && module.title}
          video={module.video}
        />
      );
    case "mod-script":
      return (
        <ScriptModule
          title={!module.hideTitle && module.title}
          embed={module.embed}
        />
      );
    default:
      return null;
  }
}
