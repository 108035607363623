import React from "react";

import { useHTMLProcessor } from "../../hooks/htmlProcessor";

export default function FormModuleCustomContentField({
  field: {
    content,
    // conditionalLogic,
    // conditonalField,
  },
  ...restProps
}) {
  const { processContent } = useHTMLProcessor();
  return (
    <div className="c-form__custom-content" {...restProps}>
      {processContent(content)}
    </div>
  );
}
