import { H } from "@jfrk/react-heading-levels";
import cx from "classnames";
import formatFileSize from "filesize";
import React from "react";

import "./FileModule.scss";

import Link from "../Link/Link";

export default function FileModule({ files, title, ...restProps }) {
  return (
    <div className="c-files" {...restProps}>
      <div className="c-section-header">
        <div className="c-section-header__wrapper">
          {title && <H className="c-section-header__title">{title}</H>}
        </div>
      </div>
      <ul className="c-files__list">
        {files.map((item, index) => {
          if (!item.file) {
            return null;
          }
          const mimeType = item.file.mimeType.split("/")[1].toUpperCase();
          const fileSize = formatFileSize(item.file.fileSize, {
            locale: "se",
            round: 0,
          });
          const title = `${item.file.title} (${mimeType}, ${fileSize})`;
          return (
            <li key={index} className="c-files__item">
              <Link className={cx("c-files__link")} to={item.file.mediaItemUrl}>
                {title}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
