import { Section } from "@jfrk/react-heading-levels";
import cx from "classnames";
import React from "react";

import Card from "../Card/Card";
import SectionHeader from "../SectionHeader/SectionHeader";

export default function PostsModuleDefault({
  title,
  dataSource,
  postsFields,
  items,
  noMarginBottom,
  cardClassName,
  wrapperClassName,
  withBorder,
  // ...restProps
}) {
  const MaybeSection = title ? Section : React.Fragment;

  return (
    <>
      {title && (
        <SectionHeader
          title={title}
          withBorder={withBorder && true}
          sectionHeader={
            dataSource.archiveLink && dataSource.postsDataPostType
              ? {
                  link: {
                    url: dataSource.postsDataPostType.url,
                    text: dataSource.postsDataPostType.labels.allItems,
                  },
                }
              : null
          }
          noMarginBottom={noMarginBottom && true}
        />
      )}
      <MaybeSection>
        <div className={cx("c-card-group", wrapperClassName)}>
          {items.map((item, index) => {
            return (
              <Card
                key={index}
                className={cardClassName}
                title={postsFields.includes("title") && item.title}
                date={postsFields.includes("date") && item.date}
                excerpt={
                  postsFields.includes("excerpt") &&
                  dataSource.postsDataSource !== "input" &&
                  item.excerpt
                }
                content={dataSource.postsDataSource === "input" && item.content}
                element={item.element}
                url={item.url}
                image={postsFields.includes("image") && item.image}
              />
            );
          })}
        </div>
      </MaybeSection>
    </>
  );
}
