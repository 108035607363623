import { useStaticQuery, graphql } from "gatsby";

export default function useOperationalStatuses() {
  return useStaticQuery(graphql`
    query ActiveOperationalStatuses {
      wp {
        operationalStatusStatuses(where: { slug: "aktiv" }) {
          nodes {
            operationalStatuses(first: 4) {
              nodes {
                id
                uri
                operationalStatusCategories(first: 1) {
                  nodes {
                    name
                    id
                    categoryIcon {
                      name
                    }
                  }
                }
                title
              }
            }
          }
        }
      }
    }
  `).wp.operationalStatusStatuses.nodes[0].operationalStatuses.nodes;
}
