import { Section } from "@jfrk/react-heading-levels";
import cx from "classnames";
import { useIntl } from "gatsby-plugin-intl";
import chunk from "lodash/chunk";
import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import slugify from "slugify";

import Card from "../Card/Card";
import CardProject from "../Card/CardProject";
import Link from "../Link";
import SectionHeader from "../SectionHeader/SectionHeader";

import "../FilterSelect/FilterSelect.scss";

const POSTS_PER_PAGE = 18;

export default function PostsModuleGrid({
  title,
  dataSource,
  postsFields,
  items,
  noMarginBottom,
  cardClassName,
  wrapperClassName,
  withBorder,
  ...restProps
}) {
  const intl = useIntl();
  const MaybeSection = title ? Section : React.Fragment;

  const [categories, setCategories] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [filteredItems, setFilteredItems] = useState(items);
  const [showMoreButton, setShowMoreButton] = useState(false);

  const [activeCategories, setActiveCategories] = useState([]);
  const [activeStatuses, setActiveStatuses] = useState([]);

  // Sort items by date (descending)
  filteredItems.sort((a, b) =>
    a.modifiedGmt > b.modifiedGmt ? -1 : b.modifiedGmt > a.modifiedGmt ? 1 : 0,
  );

  // Collect all available categories and project statuses from original set of items
  useEffect(() => {
    function removeDuplicates(array) {
      return array.filter(
        (tag, index, array) =>
          array.findIndex((t) => t.label == tag.label) == index,
      );
    }

    let allCategories = [];
    let allStatuses = [];

    items.map((item) => {
      let categories = [...item.projectCategories?.nodes];
      categories.map((category) => {
        allCategories.push({
          type: "category",
          value: category.slug,
          label: category.name,
          color: category.options.color,
        });
      });

      let statuses = [...item.projectStatuses?.nodes];
      statuses.map((status) => {
        allStatuses.push({
          type: "status",
          value: status.slug,
          label: status.options.parent, //status.name,
          color: status.options.color,
          parent: status.options.parent,
        });
      });
    });

    allCategories = removeDuplicates(allCategories);
    allCategories.sort((a, b) => a.value.localeCompare(b.value));

    allStatuses = removeDuplicates(allStatuses);

    setCategories(allCategories);
    setStatuses(allStatuses);
  }, []);

  // Filter items every time filers changes
  useEffect(() => {
    let matches = items.filter((item) => matchesFilters(item));
    let chunkedMatches = chunk(matches, POSTS_PER_PAGE);
    chunkedMatches = chunkedMatches[0] || chunkedMatches;
    setFilteredItems(chunkedMatches);
    setShowMoreButton(matches.length > POSTS_PER_PAGE);
  }, [activeCategories, activeStatuses]);

  function matchesFilters(item) {
    let categoryMatch =
      !activeCategories.length ||
      item?.projectCategories?.nodes.find((category) =>
        activeCategories.find(
          (activeCategory) => category.name === activeCategory.label,
        ),
      );

    let statusMatch =
      !activeStatuses.length ||
      item?.projectStatuses?.nodes.find((status) =>
        activeStatuses.find(
          (activeStatus) => status.options.parent === activeStatus.parent,
        ),
      );

    return categoryMatch && statusMatch;
  }

  const Option = (props) => {
    return (
      <div
        className={cx(
          `c-filter-select__option c-card__tag--${props.data.color}`,
          props.data.type === "category" && "c-filter-select__option--plain",
          // !props.data.order && "c-card__tag--pin",
        )}
      >
        <components.Option {...props} />
      </div>
    );
  };
  const MultiValueContainer = (props) => {
    return (
      <div
        className={cx(
          `c-filter-select__option c-card__tag--${props.data.color} c-filter-select__multi-value`,
          props.data.type === "category" && "c-filter-select__option--plain",
          // !props.data.order && "c-card__tag--pin",
        )}
      >
        <components.MultiValueContainer {...props} />
      </div>
    );
  };

  let archiveURL = "/projekt";
  let firstCategory = activeCategories[0];
  let firstStatus = activeStatuses[0];
  if (firstCategory || firstStatus) {
    archiveURL += "/?";
  }
  if (firstCategory) {
    archiveURL += "category=" + firstCategory.value;
    if (firstStatus) archiveURL += "&";
  }
  if (firstStatus) {
    archiveURL += "status=" + slugify(firstStatus.parent, { lower: true });
  }

  return (
    <>
      {title && (
        <SectionHeader
          title={title}
          withBorder={withBorder && true}
          sectionHeader={
            dataSource.archiveLink && dataSource.postsDataPostType
              ? {
                  link: {
                    url: dataSource.postsDataPostType.url,
                    text: dataSource.postsDataPostType.labels.allItems,
                  },
                }
              : null
          }
          noMarginBottom={noMarginBottom && true}
        />
      )}
      {(categories || statuses) && (
        <div className="c-filter-select">
          {categories && (
            <div className="c-filter-select__item">
              <span className="c-filter-select__label">Kategorier</span>
              <Select
                isMulti
                placeholder="Alla kategorier"
                isSearchable={false}
                components={{ Option, MultiValueContainer }}
                options={categories}
                onChange={(items) => {
                  setActiveCategories(items);
                }}
              />
            </div>
          )}
          {statuses && (
            <div className="c-filter-select__item">
              <span className="c-filter-select__label">Status</span>
              <Select
                isMulti
                placeholder="Alla status"
                isSearchable={false}
                components={{ Option, MultiValueContainer }}
                options={statuses}
                onChange={(items) => {
                  setActiveStatuses(items);
                }}
              />
            </div>
          )}
        </div>
      )}
      <MaybeSection {...restProps}>
        <div className={cx("c-card-group", wrapperClassName)}>
          {filteredItems.map((item, index) => {
            switch (item.postTypeInfo && item.postTypeInfo.name) {
              case "project":
                return (
                  <CardProject
                    key={index}
                    className={cardClassName}
                    title={postsFields.includes("title") && item.title}
                    date={postsFields.includes("date") && item.date}
                    excerpt={
                      postsFields.includes("excerpt") &&
                      dataSource.postsDataSource !== "input" &&
                      item.excerpt
                    }
                    content={
                      dataSource.postsDataSource === "input" && item.content
                    }
                    element={item.element}
                    url={item.url}
                    image={postsFields.includes("image") && item.image}
                    projectCategories={item.projectCategories}
                    projectStatuses={item.projectStatuses}
                  />
                );
              default:
                return (
                  <Card
                    key={index}
                    className={cardClassName}
                    title={postsFields.includes("title") && item.title}
                    date={postsFields.includes("date") && item.date}
                    excerpt={
                      postsFields.includes("excerpt") &&
                      dataSource.postsDataSource !== "input" &&
                      item.excerpt
                    }
                    content={
                      dataSource.postsDataSource === "input" && item.content
                    }
                    element={item.element}
                    url={item.url}
                    image={postsFields.includes("image") && item.image}
                  />
                );
            }
          })}
          {!filteredItems.length && (
            <div className="c-filter-select__no-results">
              <p>{intl.formatMessage({ id: "archive.no_results" })}</p>
            </div>
          )}
        </div>
        {showMoreButton && (
          <Link
            to={archiveURL}
            className="c-button c-button--large c-button--block c-button--primary u-m-t--800"
          >
            {intl.formatMessage({ id: "archive.show_more" })}
          </Link>
        )}
      </MaybeSection>
    </>
  );
}
