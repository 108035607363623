import { H, Section } from "@jfrk/react-heading-levels";
import cx from "classnames";
import React from "react";

import "./DisturbancesModule.scss";
import useOperationalStatuses from "../../hooks/operationalStatus";
import Card from "../Card/Card";
import CardOperational from "../Card/CardOperational";

export default function DisturbancesModule({
  title,
  customItems,
  showDisturbances,
  ...restProps
}) {
  const MaybeSection = title ? Section : React.Fragment;
  return (
    <div className="c-disturbances-module" {...restProps}>
      {title && <H className="c-disturbances-module__title">{title}</H>}
      <MaybeSection>
        <div className="o-grid c-disturbances-module__wrapper">
          {showDisturbances && (
            <CardOperational
              className="c-card--style-1 c-card--radius"
              postType="operational-status"
              items={useOperationalStatuses()}
            />
          )}
          {customItems &&
            customItems.map(({ title, link, content }, key) => {
              let cardStyle =
                key % 2 == 0 ? "c-card--style-2" : "c-card--style-3";
              return (
                <Card
                  key={key}
                  className={cx("c-card--radius", cardStyle)}
                  title={title}
                  excerpt={content}
                  url={link && link.url}
                />
              );
            })}
        </div>
      </MaybeSection>
    </div>
  );
}
