import cx from "classnames";
import React from "react";

import useSearchSettings from "../../hooks/searchSettings";
import SearchForm from "../SearchForm/SearchForm.js";

import "../HeroButler/HeroButler.scss";

export default function HeaderSearch({ className, ...restProps }) {
  const searchSettings = useSearchSettings();
  return (
    <div
      className={cx(
        "o-container",
        "c-header__search",
        "u-hidden-print",
        className,
      )}
      {...restProps}
    >
      <SearchForm
        className="article-wrapper c-search-form c-search-form--main"
        placeholderText={`Vad letar du efter?`}
        settings={searchSettings.displaySettings}
      />
    </div>
  );
}
