import { H, Section } from "@jfrk/react-heading-levels";
import cx from "classnames";
import { useIntl } from "gatsby-plugin-intl";
import Carousel from "nuka-carousel";
import React, { useState } from "react";

import "./GalleryModule.scss";
import Icon from "../Icon/Icon";
import { FluidImage } from "../Image";

import { useHTMLProcessor } from "./../../hooks/htmlProcessor";

function NextArrowIcon(onClick, disabled) {
  return (
    <button
      className={cx(
        "c-gallery__arrow c-gallery__arrow--next",
        disabled && "disabled",
      )}
      onClick={onClick}
      aria-label="Nästa bild"
      disabled={disabled}
    >
      <Icon name="arrow-right-1" size="12" />
    </button>
  );
}

function PrevArrowIcon(onClick, disabled) {
  return (
    <button
      className={cx(
        "c-gallery__arrow c-gallery__arrow--prev",
        disabled && "disabled",
      )}
      onClick={onClick}
      aria-label="Förra bild"
      disabled={disabled}
    >
      <Icon name="arrow-left-1" size="12" />
    </button>
  );
}

function ImageDesc({
  currentImage: {
    caption,
    photograph,
    databaseId,
    hideCaption,
    hidePhotograph,
  },
}) {
  const { processContent } = useHTMLProcessor();
  let credit = !hideCaption && photograph && photograph.name;
  let processedCaption = !hidePhotograph && caption && processContent(caption);

  if (!credit && !processedCaption) return null;
  return (
    <div className="c-image__caption" id={`desc-for-image-${databaseId}`}>
      {processedCaption && <>{processedCaption}</>}
      {credit && (
        <p className="c-image__photographer">{"Fotograf: " + credit}</p>
      )}
    </div>
  );
}

export default function GalleryModule({
  title,
  images,
  settings: { display: displaySettings, pauseOnHover },
  ...restProps
}) {
  const [currentImage, setCurrentImage] = useState(images[0]);

  const intl = useIntl();

  displaySettings = Array.isArray(displaySettings) && displaySettings;

  const hidePhotograph =
    displaySettings && displaySettings.includes("hide_photograph");
  const hideCaption = displaySettings && displaySettings.includes("hide_text");
  const activateAutoplay =
    displaySettings && displaySettings.includes("autoplay");

  let [autoplay, setAutoplay] = useState(activateAutoplay);

  const settings = {
    enableKeyboardControls: true,
    defaultControlsConfig: {
      pagingDotsContainerClassName: "c-gallery__dots",
    },
    renderCenterLeftControls: ({ previousSlide, currentSlide }) => {
      const disabled = currentSlide === 0;
      return PrevArrowIcon(previousSlide, disabled);
    },
    renderCenterRightControls: ({ nextSlide, currentSlide, slideCount }) => {
      const disabled = currentSlide + 1 === slideCount;
      return NextArrowIcon(nextSlide, disabled);
    },
    dragging: true,
    renderAnnounceSlideMessage: ({ currentSlide, slideCount }) => {
      return `Bild ${currentSlide + 1} av ${slideCount}`;
    },
    slidesToShow: 1,
    afterSlide: (slideIndex) => {
      setCurrentImage(images[slideIndex]);
    },
    wrapAround: true,
    autoplay: autoplay,
    pauseOnHover: autoplay && pauseOnHover,
    keyCodeConfig: {
      pause: [32, 13],
    },
  };

  const toggleAutoplay = (button) => {
    const currentLabel = button.getAttribute("aria-label");

    if (currentLabel === intl.formatMessage({ id: "Pause" })) {
      button.setAttribute("aria-label", intl.formatMessage({ id: "Play" }));
      setAutoplay(false);
    } else {
      button.setAttribute("aria-label", intl.formatMessage({ id: "Pause" }));
      setAutoplay(true);
    }
  };

  return (
    <div className={cx("c-gallery", { ...restProps })}>
      {title && (
        <div className="c-section-header">
          <div className="c-section-header__wrapper">
            {title && <H className="c-section-header__title">{title}</H>}
          </div>
        </div>
      )}
      <div className="c-gallery__image-wrapper">
        <div className="c-gallery__carousel-wrapper">
          <Carousel {...settings}>
            {images.length > 0 &&
              images.map(
                (
                  { src, srcSet, width, height, altText, databaseId },
                  index,
                ) => {
                  return (
                    <FluidImage
                      key={index}
                      base64=""
                      src={src}
                      srcSet={srcSet}
                      aspectRatio={1216 / 532}
                      width={width}
                      height={height}
                      alt={altText}
                      className="c-gallery__image"
                      aria-describedby={`desc-for-image-${databaseId}`}
                      role="img"
                      aria-label={altText}
                    />
                  );
                },
              )}
          </Carousel>
          {activateAutoplay && (
            <button
              className={cx(
                "c-gallery__autoplay-control",
                (currentImage.photograph.name || currentImage.caption) &&
                  "c-gallery__autoplay-control--w-caption",
              )}
              onClick={(e) => toggleAutoplay(e.target)}
              aria-label={intl.formatMessage({ id: "Pause" })}
            >
              <Icon
                name={autoplay ? "button-pause" : "button-play-1"}
                size="1.5rem"
              />
            </button>
          )}
        </div>
        <ImageDesc
          currentImage={currentImage}
          hideCaption={hideCaption}
          hidePhotograph={hidePhotograph}
        />
      </div>
    </div>
  );
}
