import cx from "classnames";
import FocusTrap from "focus-trap-react";
import { useIntl } from "gatsby-plugin-intl";
import React, { useState, useEffect } from "react";
import { useTransition, animated } from "react-spring";
import useMedia from "use-media";
import useSSR from "use-ssr";

import {
  getHamburgerHidden,
  getTopNavigationCentered,
} from "../../hooks/header";
import { useIsNotIE11 } from "../../hooks/ie11";
import { useMenu } from "../../hooks/menus";
import useSearchSettings from "../../hooks/searchSettings";
import { getTokenValue } from "../../styles/tokens";
import ClientOnly from "../ClientOnly";
import CookieConsent from "../CookieConsent";
import Hero from "../Hero/Hero";
import Icon from "../Icon";
import Link from "../Link";
import Logo from "../Logo/Logo";
import Menu from "../Menu/Menu";
import MobileNavigation from "../MobileNavigation";
import ModuleArea from "../ModuleArea/ModuleArea";
import SearchForm from "../SearchForm/SearchForm.js";

import "./Header.scss";

const AnimatedMobileNavigation = animated(MobileNavigation);

export default function Header({
  hideSearchForm = false,
  hero,
  children,
  sliderArea,
  ...restProps
}) {
  const searchSettings = useSearchSettings();
  const intl = useIntl();
  const { isBrowser } = useSSR();
  let headerMenu = useMenu("horizontal-menu");
  let helpMenu = useMenu("mobile-help-menu") || useMenu("help-menu");
  let mobileHelpMenu = useMenu("mobile-help-menu");
  let secondaryMenu = useMenu("secondary-menu");
  let isNotIE11 = useIsNotIE11();
  const isTopNavigationCentered = getTopNavigationCentered();

  const isDesktop = useMedia({
    minWidth: getTokenValue("metrics.md-breakpoint"),
  });
  const [isMobileNavigationVisible, setIsMobileNavigationVisible] = useState(
    false,
  );
  const mobileNavigationTransitions = useTransition(
    isMobileNavigationVisible ? ["backdrop", "menu"] : [],
    null,
    {
      from: (item) =>
        item === "backdrop"
          ? { opacity: 0 }
          : { transform: `translate(100%, 0)` },
      enter: (item) =>
        item === "backdrop" ? { opacity: 1 } : { transform: `translate(0, 0)` },
      leave: (item) =>
        item === "backdrop"
          ? { opacity: 0 }
          : { transform: `translate(100%, 0)` },
    },
  );

  useEffect(() => {
    if (isNotIE11) {
      document.documentElement.classList.toggle(
        "navigation--is-active",
        isMobileNavigationVisible,
      );
    }
  }, [isNotIE11, isMobileNavigationVisible]);

  const isHambugerHidden = getHamburgerHidden();

  return (
    <header {...restProps}>
      <Link
        className="skip-link u-visually-hidden u-visually-hidden--focusable"
        to="#content"
      >
        {intl.formatMessage({ id: "Skip to main content" })}
      </Link>
      <ClientOnly>
        <CookieConsent />
      </ClientOnly>
      <div className="c-header o-container">
        <div className="c-header__container">
          <div className="c-header__logo">
            <Logo linkTo="/" aria-label="Gå till startsidan" />
          </div>
          {helpMenu && helpMenu.items && helpMenu.items.length && (
            <nav
              aria-label={intl.formatMessage({ id: "Help menu" })}
              className="u-hidden-print"
            >
              <ul className="c-header__help-menu">
                {helpMenu.items.map((item, index) => {
                  return (
                    <li className="c-header__help-menu-items" key={index}>
                      <Link to={item.url} className="c-header__help-menu-link">
                        {item.label}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </nav>
          )}
          {!hideSearchForm && (
            <SearchForm
              settings={searchSettings.displaySettings}
              className="c-search-form c-search-form--header"
            />
          )}
          {((isDesktop && !isHambugerHidden) || !isDesktop) && (
            <nav aria-label="Huvudnavigation">
              {isBrowser && (
                <button
                  type="button"
                  id="primary-navigation"
                  aria-label={intl.formatMessage({ id: "Menu" })}
                  aria-expanded={isMobileNavigationVisible && true}
                  className="c-header__menu-button u-hidden-print"
                  onClick={() => setIsMobileNavigationVisible(true)}
                >
                  <span className="c-header__help-menu-text">
                    {intl.formatMessage({ id: "Menu" })}
                  </span>
                  <Icon name="menu-header" size="16" role="presentation" />
                </button>
              )}
              {mobileNavigationTransitions.map(({ item, key, props }) => {
                switch (item) {
                  case "backdrop": {
                    return (
                      <animated.div
                        key={key}
                        style={props}
                        className="c-flyout__backdrop"
                        onClick={() => setIsMobileNavigationVisible(false)}
                      />
                    );
                  }
                  case "menu": {
                    return (
                      <FocusTrap
                        key={key}
                        focusTrapOptions={{
                          clickOutsideDeactivates: true,
                          onDeactivate: () => {
                            setIsMobileNavigationVisible(false);
                          },
                        }}
                      >
                        <div>
                          <AnimatedMobileNavigation
                            style={props}
                            shortcuts={mobileHelpMenu}
                            onCloseClick={() =>
                              setIsMobileNavigationVisible(false)
                            }
                            onLinkClick={() =>
                              setIsMobileNavigationVisible(false)
                            }
                          />
                        </div>
                      </FocusTrap>
                    );
                  }
                }
              })}
            </nav>
          )}
        </div>
      </div>
      {(isDesktop || !isBrowser) && headerMenu ? (
        <Menu
          className="primary-navigation"
          menu={headerMenu}
          inverse={true}
          centered={isTopNavigationCentered && isTopNavigationCentered}
          name="Header menu"
        />
      ) : null}
      {sliderArea && <ModuleArea area={sliderArea} displayGrid={false} />}
      {/** TODO: Deprecate hero fields */}
      {hero && <Hero image={hero.heroImage} text={hero.text} />}
      {(isDesktop || !isBrowser) && secondaryMenu ? (
        <Menu
          className="secondary-navigation"
          menu={secondaryMenu}
          inverse={true}
          centered={true}
          name="Secondary menu"
        />
      ) : null}
      {children}
    </header>
  );
}
