import React from "react";

import "./VideoModule.scss";

export default function VideoModuleIframe({ video }) {
  return (
    <div className="c-video__embed">
      <iframe
        src={video}
        title="Inbäddad film"
        allowtransparency="true"
        allowFullScreen
      />
    </div>
  );
}
