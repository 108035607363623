import { Section } from "@jfrk/react-heading-levels";
import React, { Suspense } from "react";

import Graphic from "../ArcGISMap/Graphic";
import SectionHeader from "../SectionHeader/SectionHeader";

const ArcGISMap = React.lazy(() => import("../ArcGISMap/ArcGISMap"));

export default function PostsModuleMap({
  title,
  dataSource,
  items,
  defaultView = {},
  // ...restProps
}) {
  const MaybeSection = title ? Section : React.Fragment;

  return (
    <>
      {title && (
        <SectionHeader
          title={title}
          withBorder={true}
          sectionHeader={
            dataSource.archiveLink && dataSource.postsDataPostType
              ? {
                  link: {
                    url: dataSource.postsDataPostType.url,
                    text: dataSource.postsDataPostType.labels.allItems,
                  },
                }
              : null
          }
        />
      )}
      <MaybeSection>
        <Suspense fallback={"Loading…"}>
          <ArcGISMap
            {...{
              center: [13.1574022, 55.3762638],
              zoom: 15,
              aspectRatio: 16 / 9,
            }}
          >
            {items.map((item) => {
              return (
                <Graphic
                  key={item.id}
                  longitude={13.1574022}
                  latitude={55.3762638}
                />
              );
            })}
          </ArcGISMap>
        </Suspense>
      </MaybeSection>
    </>
  );
}
