import React from "react";

import { useMenu } from "../../hooks/menus";

import FooterShortcuts from "./FooterShortcuts";

export default function FooterShortcutsContainer({ ...restProps }) {
  let menu = useMenu("footer-shortcuts");
  if (!menu || !menu.items || menu.items.length === 0) {
    return null;
  }
  return <FooterShortcuts shortcuts={menu.items} {...restProps} />;
}
