import { useGraphic } from "esri-loader-hooks";
import React, { useContext, useEffect } from "react";

import MapContext from "./MapContext";

export default function Graphic({ longitude, latitude, ...restProps }) {
  const { view } = useContext(MapContext);
  useGraphic(view, {
    geometry: {
      type: "point", // autocasts as new Point()
      longitude,
      latitude,
    },
    symbol: {
      type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
      color: [226, 119, 40],
    },
  });
  return null;
}
