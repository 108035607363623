import cx from "classnames";
import { useIntl } from "gatsby-plugin-intl";
import React from "react";

import { RoundIcon } from "../Icon";
import Link from "../Link/Link";

import "./HeaderFrontPageMenu.scss";

export default function HeaderFrontPageMenu({
  items = [],
  className,
  ...restProps
}) {
  const intl = useIntl();

  return (
    <nav
      className={cx("c-menu-grid u-hidden-print o-container", className)}
      aria-label={intl.formatMessage({ id: "Primary navigation" })}
      {...restProps}
    >
      <ul className="c-menu-grid__list">
        {items.map((item, index) => {
          return (
            <li key={index} className="c-menu-grid__item">
              <Link
                className="c-menu-grid__link"
                to={item.url}
                target={item.target}
              >
                <RoundIcon
                  name={item.icon}
                  className="c-menu-grid__icon"
                  bgColor={`var(--theme-${item.themeColor})`}
                />
                <div className="c-menu-grid__text">
                  <span className="c-menu-grid__label">{item.label}</span>
                  {item.description && (
                    <p className="c-menu-grid__description">
                      {item.description}
                    </p>
                  )}
                </div>
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}
