import { navigate } from "gatsby";
import qs from "query-string";
import React, { useState, useRef } from "react";
import Autosuggest from "react-autosuggest";

import {
  getSuggestionValue,
  getSuggestions,
  getFoundSuggestions,
  renderInputComponent,
  renderSuggestions,
} from "../../hooks/autoSuggestions";
import useSearchSettings from "../../hooks/searchSettings";

import "./SearchForm.scss";

export default function SearchForm({
  placeholderText,
  settings,
  ...restProps
}) {
  const { searchButtonText, searchLabelText, searchPlaceholderText } = settings;
  const {
    searchWord: { suggestions },
  } = useSearchSettings();

  const [searchInput, setSearchInput] = useState("");

  const [foundSuggestions, setFoundSuggestions] = useState([]);

  let submitRef = useRef(null);
  let inputRef = useRef(null);

  const inputProps = {
    id: "search-form-query",
    name: "query",
    className: "c-search-form__field",
    type: "search",
    placeholder: placeholderText || searchPlaceholderText,
    value: searchInput,
    ref: inputRef,
    onChange: (event, { newValue }) => {
      setSearchInput(newValue);
    },
  };

  return (
    <form
      className="c-search-form"
      role="search"
      onSubmit={(event) => {
        event.preventDefault();
        navigate(`/sok?${qs.stringify({ query: searchInput })}`);
      }}
      ref={submitRef}
      {...restProps}
    >
      <Autosuggest
        suggestions={foundSuggestions}
        renderInputComponent={(inputProps) =>
          renderInputComponent(
            searchLabelText,
            searchButtonText,
            "u-visually-hidden",
            inputProps,
            searchInput,
          )
        }
        renderSuggestion={(suggestion) => {
          return renderSuggestions(
            suggestion,
            searchInput,
            submitRef,
            inputRef,
          );
        }}
        inputProps={inputProps}
        getSuggestionValue={getSuggestionValue}
        onSuggestionsFetchRequested={({ value }) => {
          setFoundSuggestions(
            getFoundSuggestions(value, getSuggestions(suggestions)),
          );
        }}
        onSuggestionsClearRequested={() => {
          setFoundSuggestions([]);
        }}
        shouldRenderSuggestions={(value) => {
          return value.trim().length > 1;
        }}
      />
    </form>
  );
}
