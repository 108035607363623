import { Section, H } from "@jfrk/react-heading-levels";
import cx from "classnames";
import React from "react";

import { useHTMLProcessor } from "../../hooks/htmlProcessor";

import "./TextModule.scss";

export default function TextModule({
  title,
  content,
  settings,
  contentMedia,
  ...restProps
}) {
  const { processPageContent } = useHTMLProcessor();
  let { content: processedContent } = processPageContent(content, {
    contentMedia,
  });
  const textSize = settings.fontSize.replace(/text-/, "");
  return (
    <article
      className={cx(
        "c-article",
        !settings.hideBoxFrame && "c-text-module-card",
        textSize && `c-text-module-card--${textSize}`,
      )}
      {...restProps}
    >
      {title && <H className="c-text-module-card__title">{title}</H>}
      <Section>{processedContent}</Section>
    </article>
  );
}
