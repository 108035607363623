import { H } from "@jfrk/react-heading-levels";
import VideoUrlParser from "js-video-url-parser";
import React, { Suspense } from "react";

import ClientOnly from "../ClientOnly";

import PlainVideoPlayer from "./PlainVideoPlayer";
import VideoModuleIframe from "./VideoModuleIframe";

import "./VideoModule.scss";

const PlyrVideoPlayer = React.lazy(() => import("./PlyrVideoPlayer"));

export default function VideoModule({ title, video, ...restProps }) {
  const { provider } = VideoUrlParser.parse(video) || {
    provider: "video",
  };

  return (
    <div className="c-video" {...restProps}>
      {title && (
        <div className="c-section-header">
          <div className="c-section-header__wrapper">
            <H className="c-section-header__title">{title}</H>
          </div>
        </div>
      )}
      {provider === "video" ? (
        <ClientOnly fallback={<PlainVideoPlayer video={video} />}>
          <Suspense fallback={<div>Laddar videospelare…</div>}>
            <PlyrVideoPlayer video={video} />
          </Suspense>
        </ClientOnly>
      ) : (
        <VideoModuleIframe video={video} />
      )}
    </div>
  );
}
