import { Section, H } from "@jfrk/react-heading-levels";
import cx from "classnames";
import React from "react";

import { useHTMLProcessor } from "../../hooks/htmlProcessor";

import "./FactModule.scss";

export default function FactModule({
  title,
  content,
  contentMedia,
  ...restProps
}) {
  const { processPageContent } = useHTMLProcessor();
  let { content: processedContent } = processPageContent(content, {
    contentMedia,
  });
  return (
    <article
      className={cx("c-article", "c-fact-module-card c-fact-module-card--md")}
      {...restProps}
    >
      {title && <H className="c-fact-module-card__title">{title}</H>}
      <Section>{processedContent}</Section>
    </article>
  );
}
