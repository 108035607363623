import React from "react";

import { useHasMounted } from "../../hooks/hasMounted";

export default function ClientOnly({ fallback = null, children }) {
  if (!useHasMounted()) {
    return fallback;
  }
  return <>{children}</>;
}
