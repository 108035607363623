import { useStaticQuery, graphql } from "gatsby";

import { getPage } from "../utils/pageTree";

import usePages from "./pages";

export default function useMenus() {
  let pages = usePages();
  return useStaticQuery(graphql`
    query Menus {
      wp {
        menus {
          nodes {
            menuItems {
              nodes {
                connectedObject {
                  ... on WP_Page {
                    id
                    postTypeInfo {
                      name
                    }
                  }
                }
                label
                description
                url
                target
                themeColor
                icon
              }
            }
            location
          }
        }
      }
    }
  `).wp.menus.nodes.map((menu) => ({
    ...menu,
    items: menu.menuItems.nodes.map((menuItem) => {
      let {
        connectedObject,
        label,
        description,
        url,
        target,
        themeColor,
        icon,
      } = menuItem;
      let { postTypeInfo: { name: type = "custom" } = {}, id } =
        connectedObject || {};
      let content = type === "page" ? getPage(pages, id) : {};
      return {
        type,
        url,
        target: connectedObject && connectedObject.id ? target : "_blank",
        ...content,
        label,
        description: description || (content && content.description),
        themeColor,
        icon,
      };
    }),
  }));
}

export function getMenu(menus, location) {
  return menus.find((menu) => menu.location === location);
}

export function useMenu(location) {
  return getMenu(useMenus(), location);
}
