import cx from "classnames";
import React from "react";

import "./TableModule.scss";

export default function TableModule({ tableData, ...restProps }) {
  let { title, items, displayAlternatives, textSize } = tableData;
  displayAlternatives =
    displayAlternatives &&
    displayAlternatives.map((alternative) => {
      return "c-table--" + alternative.replace("table-", "");
    });

  textSize = textSize.replace("table-", "");

  return (
    <div className="c-table__wrapper">
      <table
        className={cx(
          "c-table",
          displayAlternatives,
          textSize ? `c-table--${textSize}` : "",
        )}
        {...restProps}
      >
        {title && <caption className="c-table__title">{title}</caption>}

        <thead className="c-table__head">
          <tr className="c-table__table-row">
            {items[0].map((title, index) => {
              return (
                <th className="c-table__table-head" scope="col" key={index}>
                  {title}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="c-table__body">
          {items.slice(1).map((item, index) => {
            return (
              <tr className="c-table__table-row" key={index}>
                {item.map((data, index) => {
                  return (
                    <td className="c-table__table-data" key={index}>
                      {data}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
