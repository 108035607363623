// import { Link } from "gatsby";
import { H } from "@jfrk/react-heading-levels";
import cx from "classnames";
import React from "react";

import "./Card.scss";
import Excerpt from "../Excerpt/Excerpt";
import RoundIcon from "../Icon/RoundIcon";
import { FluidImage } from "../Image";
import Link from "../Link/Link";
import Time from "../Time";

export default function Card({
  className,
  title,
  date,
  url,
  excerpt,
  content,
  image,
  category,
  categoryIconName,
  ...restProps
}) {
  return (
    <article className={cx("c-card c-card--spacing", className)} {...restProps}>
      <div className="c-card__content">
        {title && (
          <div className="c-card__title">
            <div>
              <H className="c-card__title-heading">
                {url ? (
                  <Link to={url} className="c-card__title-heading-link">
                    {title}
                  </Link>
                ) : (
                  title
                )}
              </H>
            </div>
            {date && (
              <Time
                className="c-card__date"
                date={date}
                format={{
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                }}
              />
            )}
            {category && (
              <div className="c-card__category">
                <RoundIcon
                  name={categoryIconName}
                  size="1rem"
                  color="var(--color-light)"
                  bgColor="var(--color-tertiary)"
                />
                {category}
              </div>
            )}
          </div>
        )}
        {excerpt && <Excerpt className="c-card__body" text={excerpt} />}
        {content && <div className="c-card__body c-article">{content}</div>}
      </div>
      {image && (
        <FluidImage
          base64={image.base64}
          src={image.src}
          srcSet={image.srcSet}
          srcWebp={image.srcWebp}
          srcSetWebp={image.srcSetWebp}
          aspectRatio={image.aspectRatio}
          width={image.width}
          height={image.height}
          alt={image.altText}
          className={cx("c-card__image")}
        />
      )}
    </article>
  );
}
