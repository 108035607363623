import React from "react";

import { usePageContext } from "../../hooks/pageContext";
import usePages from "../../hooks/pages";
import { useSet } from "../../hooks/set";
import { getAncestors } from "../../utils/pageTree";

import MobileNavigation from "./MobileNavigation";

export default function MobileNavigationContainer({
  shortcuts,
  onCloseClick,
  onLinkClick,
  ...restProps
}) {
  let { page: currentPage } = usePageContext();
  let allPages = usePages();
  const [
    expandedItems,
    { add: addExpandedItem, delete: removeExpandedItem },
  ] = useSet(() =>
    currentPage
      ? [
          currentPage.id,
          ...getAncestors(allPages, currentPage.id).map((page) => page.id),
        ]
      : [],
  );

  return (
    <MobileNavigation
      allPages={allPages}
      expandedItems={expandedItems}
      onItemExpand={addExpandedItem}
      onItemCollapse={removeExpandedItem}
      shortcuts={shortcuts}
      onCloseClick={onCloseClick}
      onLinkClick={onLinkClick}
      {...restProps}
    />
  );
}
