import React from "react";

import useFooterBlocks from "../../hooks/footer";
import { useMenu } from "../../hooks/menus";

import Footer from "./Footer";

export default function FooterContainer({ ...restProps }) {
  let footerLegalMenu = useMenu("footer-legal-menu");
  let footerQuickNav = useMenu("footer-quick-nav");
  let footerBlocks = useFooterBlocks();

  return (
    <Footer
      footerBlocks={footerBlocks}
      footerLegalMenu={footerLegalMenu}
      footerQuickNav={footerQuickNav}
      {...restProps}
    />
  );
}
