import React from "react";

import "./VideoModule.scss";

export default function PlainVideoPlayer({ video, ...restProps }) {
  return (
    <video playsInline controls {...restProps}>
      <source src={video} type="video/mp4" />
    </video>
  );
}
