import { useStaticQuery, graphql } from "gatsby";

export default function useHeader() {
  return useStaticQuery(graphql`
    query headerSettings {
      wp {
        header {
          sidhuvud {
            hideHamburger
            hideBreadcrumbs
            centerTopNavigation
          }
        }
      }
    }
  `).wp.header;
}

export function getHamburgerHidden() {
  return useHeader().sidhuvud.hideHamburger;
}

export function getBreadcrumbsHidden() {
  return useHeader().sidhuvud.hideBreadcrumbs;
}

export function getTopNavigationCentered() {
  return useHeader().sidhuvud.centerTopNavigation;
}
