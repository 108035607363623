import React from "react";

import { useMenu } from "../../hooks/menus";

import HeaderFrontPageMenu from "./HeaderFrontPageMenu";

export default function HeaderFrontPageMenuContainer({ ...restProps }) {
  let menu = useMenu("frontpage");
  return (
    <>
      {menu && menu.items && (
        <HeaderFrontPageMenu {...restProps} items={menu.items} />
      )}
    </>
  );
}
