import React from "react";

export default function LogoPart0() {
  return (
    <g>
      <path d="M34.63 14.49v31.25c-.1 2.64-1.89 3.25-4.82 4.03-2.07.54-4.47 1.03-6.1 1.36l-1.5.3a7.61 7.61 0 00-3.85 2.16 7.6 7.6 0 00-3.87-2.16l-1.51-.3c-1.62-.33-4.01-.82-6.08-1.36-2.93-.78-4.73-1.38-4.85-4.03V14.5h-.3v31.25c.14 3.03 2.44 3.64 5.12 4.33 2.08.56 4.47 1.05 6.1 1.37l1.5.31a7.43 7.43 0 013.81 2.16l.1.09.1-.09a7.43 7.43 0 013.8-2.16 105.13 105.13 0 007.62-1.68c2.67-.7 4.97-1.3 5.07-4.33V14.5h-.34zM1.12 6.8l1.22 1.34V9h3.53V6.8H1.12zM30.28 6.8h-7.6v2.19h7.6V6.8zM9.97 9.53H2.34v2.19h7.63V9.53zM26.23 9.53h-7.6v2.19h7.6V9.53zM30.8 6.8v2.19h3.56v-.8L35.6 6.8H30.8zM30.28 12.25h-7.6v2.24h7.6v-2.24zM34.36 9.53h-7.6v2.19h7.6V9.53zM5.87 12.25H2.34v.66l-.58.6v.98h4.11v-2.24zM22.15 12.25h-7.61v2.24h7.61v-2.24zM34.36 12.25h-3.55v2.24h4.13v-.98l-.58-.6v-.66zM22.15 6.8h-7.61v2.19h7.61V6.8zM14 12.25H6.4v2.24H14v-2.24zM18.1 9.53h-7.61v2.19h7.61V9.53zM14 6.8H6.4v2.19H14V6.8zM34.65 4.59H33.3V2.23h1.36V4.6zm-7.67 0h-1.4V2.23h1.36l.04 2.36zm-7.96 0h-1.36V2.23h1.36V4.6zm-7.93 0H9.73V2.23h1.35l.01 2.36zm-7.67 0H2.04V2.23H3.4V4.6zM35.4 0v.83h-1V0h-.86v.83h-.99V0h-.85v2.42h-.85v1.1h-1.2v-1.1h-.86V0h-1v.83h-1V0h-.97v.83h-1V0h-1.05v2.42h-.85v1.1h-1.24v-1.1h-.8V0h-1.06v.83h-1V0h-.97v.83h-1V0h-1.04v2.42H15v1.1h-1.26v-1.1h-.81V0h-1.05v.83h-1V0H9.9v.83h-1V0H7.85v2.42H7v1.1H5.79v-1.1h-.85V0h-.86v.83h-.94V0h-.85v.83h-1V0H0v1.76l.32.33v3.84l.3.33h35.45l.31-.35v-3.8l.28-.35V0H35.4z" />
      <path d="M5.97 31.95a1.25 1.25 0 01-.35.9 1.18 1.18 0 01-.88.37c-.3-.02-.59-.1-.85-.24l-.1.14A1.97 1.97 0 005.4 34c.52 0 1.03-.22 1.4-.6a2.08 2.08 0 000-2.9 1.96 1.96 0 00-2.32-.36c-.28.15-.52.37-.7.64l.1.14c.26-.15.55-.23.85-.23a1.15 1.15 0 01.89.35 1.22 1.22 0 01.34.91M31.41 30.71l1.21-.9-.16 1.53 1.35.61-1.35.62.16 1.52-1.21-.9-1.2.9.16-1.52-1.36-.62 1.36-.61-.15-1.53 1.19.9zM20.28 23.7h-3.85v2.14h3.85V23.7zM15.9 25.08h-2.84v.76h2.84v-.76zM23.66 25.08h-2.84v.76h2.84v-.76zM11.7 39.65H9.33v2.1h2.37v-2.1zM15.43 39.65h-3.2v2.1h3.2v-2.1zM15.43 42.29h-6.1v2.1h6.1v-2.1zM24.47 44.94h-3.15v2.11h3.15v-2.1zM24.14 32.55h.66v1.25h2.6v-1.25h.65v-.85h-3.91v.85zM15.43 44.94h-3.2v2.11h3.2v-2.1zM23.28 26.4h-9.84v2.12h9.84V26.4zM27.39 42.29h-6.07v2.1h6.07v-2.1zM24.8 34.35v1.26h-2.44v-1.26h-3.74v2.1h8.77v-2.1H24.8zM23.28 29.05h-4.66v2.11h4.66v-2.1zM24.47 39.65h-3.15v2.1h3.15v-2.1zM18.1 29.05h-4.66v2.11h4.65v-2.1zM24.99 44.94v2.11H28.25l-.86-2.1H25zM14.36 31.7v2.1h8v-2.1h-8zM11.7 44.94H9.33l-.86 2.11H11.7v-2.1zM9.33 37v2.1h6.1v-.68h5.9v.68h6.07V37H9.33zM11.91 33.8v-1.25h.7v-.85H8.68v.85h.64v1.25h2.58zM27.39 39.65h-2.4v2.1h2.4v-2.1zM18.1 36.46v-2.11h-3.74v1.26H11.9v-1.26H9.33v2.11h8.76zM18.6 17.87V16.8h.4v-.51h-.4v-.53h-.49v.53h-.42v.51h.42v1.06l-4.33 5.3h2.26l2.06-5.16.26.11.25-.11 2.07 5.16h2.25l-4.33-5.3z" />
      <path d="M16.6 23.17h3.51l-1.75-4.37-1.75 4.37zM11.08 18.3a.47.47 0 00-.07-.4.45.45 0 00-.35-.17.43.43 0 00-.36.18.46.46 0 00-.07.4.5.5 0 00.19.38v12.47h.5V18.69a.47.47 0 00.2-.38M6.68 27.15l3.18-2.17v-5.8a4.26 4.26 0 00-3.18 2.18c-1.1 2 0 5.8 0 5.8M26.54 18.3a.47.47 0 00-.13-.32.45.45 0 00-.31-.14.43.43 0 00-.32.14.46.46 0 00-.12.33.5.5 0 00.18.38v12.47h.5V18.69a.47.47 0 00.2-.38M30.02 27.15l-3.18-2.17v-5.8a4.26 4.26 0 013.18 2.18c1.1 2 0 5.8 0 5.8M16.85 43.82a.43.43 0 01-.4-.08.45.45 0 01-.17-.36.47.47 0 01.18-.37.44.44 0 01.39-.07.43.43 0 01.39.07.45.45 0 01.17.37.47.47 0 01-.17.36.44.44 0 01-.4.08zm3.95-3.31v-1.55h-4.85v8.9h4.85v-1.65h-1.7v-.7h1.7v-4.3h-1.7v-.7h1.7z" />
    </g>
  );
}
