import { unblock } from "@jeanfredrik/yett";
import differenceInMonths from "date-fns/differenceInMonths";
import React, { useEffect } from "react";

import useCookieConsentSettings from "../../hooks/cookieConsentSettings";
import { useStore } from "../../hooks/store";

import CookieConsent from "./CookieConsent";
import "./CookieConsent.scss";

export default function CookieConsentContainer({ ...restProps }) {
  let [store, setStore] = useStore();

  useEffect(() => {
    if (store.cookieConsent && store.cookieConsent.answer === "accept") {
      unblock();
      if (window._paq) {
        window._paq.push(["setConsentGiven"]);
      }
    }
  }, [store.cookieConsent && store.cookieConsent.answer]);

  const {
    cookieconsentactive,
    cookieconsentposition,
    cookieconsentapprovebutton,
    cookieconsentdeclinebutton,
    cookieconsentdescription,
    cookieconsentmorelink,
    cookieconsentmorelinkurl,
    cookieconsenttitle,
  } = useCookieConsentSettings();

  if (
    !cookieconsentactive ||
    (store.cookieConsent &&
      store.cookieConsent.answer &&
      store.cookieConsent.answeredAt &&
      differenceInMonths(new Date(), new Date(store.cookieConsent.answeredAt)) <
        12)
  ) {
    return null;
  }

  return (
    <CookieConsent
      title={cookieconsenttitle}
      morelink={cookieconsentmorelink}
      morelinkurl={cookieconsentmorelinkurl}
      description={cookieconsentdescription}
      declinebutton={cookieconsentdeclinebutton}
      approvebutton={cookieconsentapprovebutton}
      position={cookieconsentposition}
      onAccept={() => {
        setStore((store) => {
          store.cookieConsent.answer = "accept";
          store.cookieConsent.answeredAt = new Date().toISOString();
        });
      }}
      onDeny={() => {
        setStore((store) => {
          store.cookieConsent.answer = "deny";
          store.cookieConsent.answeredAt = new Date().toISOString();
        });
      }}
      {...restProps}
    />
  );
}
