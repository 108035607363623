import cx from "classnames";
import { useIntl } from "gatsby-plugin-intl";
import React from "react";
import useMedia from "use-media";

import { getTokenValue } from "../../styles/tokens";
import "./FooterShortcuts.scss";
import Icon from "../Icon/Icon";
import Link from "../Link";

export default function FooterShortcuts({ shortcuts, ...restProps }) {
  const intl = useIntl();
  const isDesktop = useMedia({
    minWidth: getTokenValue("metrics.lg-breakpoint"),
  });

  return (
    <nav
      className={cx("c-shortcuts", "o-container", "u-hidden-print")}
      aria-label={intl.formatMessage({ id: "Shortcuts" })}
      {...restProps}
    >
      <ul
        className={cx(
          "c-shortcuts__list",
          isDesktop ? "o-section" : "o-section--full-width",
        )}
      >
        {shortcuts.map((shortcut, index) => {
          return (
            <li key={index} className="c-shortcuts__item">
              <Link className="c-shortcuts__link" to={shortcut.url}>
                <Icon name={shortcut.icon} />
                {shortcut.label}
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}
