import { Section, H } from "@jfrk/react-heading-levels";
import cx from "classnames";
import React from "react";

import { useHTMLProcessor } from "../../hooks/htmlProcessor";

import "./ScriptModule.scss";

export default function ScriptModule({ title, embed, ...restProps }) {
  const { embedCode } = embed;

  const { processContent } = useHTMLProcessor();
  const processedContent = processContent(embedCode);

  return (
    <div className={cx("c-script-module")} {...restProps}>
      {title && <H className="c-script-module__title">{title}</H>}
      {processedContent}
    </div>
  );
}
