import cx from "classnames";
import { useIntl } from "gatsby-plugin-intl";
import React from "react";

import useRekai from "../../hooks/rekai";
import { RoundIcon } from "../Icon";
import Link from "../Link/Link";

import "../HeroButler/HeroButler.scss";

export default function HeaderShortcuts({ className, ...restProps }) {
  const intl = useIntl();
  const { isLoading, predictions } = useRekai({ nrofhits: 4 });
  const shortcuts = isLoading
    ? []
    : predictions.map((item) => ({ ...item, path: item.url }));

  return (
    <div
      className={cx(
        "o-container",
        "c-recommended",
        "u-hidden-print",
        className,
      )}
      {...restProps}
    >
      <p className="u-visually-hidden" id="shortcuts">
        {intl.formatMessage({ id: "Shortcuts" })}:
      </p>
      {shortcuts.length > 0 && (
        <ul aria-describedby="shortcuts" className="c-recommended-list">
          {shortcuts.map((item, index) => {
            return (
              <li key={index} className="c-recommended-list__item">
                <Link className="c-recommended-list__item-link" to={item.path}>
                  <RoundIcon
                    className="c-recommended-list__item-icon"
                    name="arrow-right-1"
                    bgColor={`var(--color-light)`}
                    color="var(--color-action)"
                  />
                  {item.title}
                </Link>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}
