import cx from "classnames";
import React from "react";

import "./Button.scss";

import Icon from "../Icon/Icon";
import RoundIcon from "../Icon/RoundIcon";
import Link from "../Link/Link";

export default function Button({
  title,
  className,
  buttonModifier,
  link,
  roundIcon = false,
  iconBgColor,
  iconColor,
  iconBeforeName,
  iconAfterName,
  iconBeforeSize,
  iconAfterSize,
  iconModifier,
  iconBefore,
  iconAfter,
  ...restProps
}) {
  const iconComponent = (iconName = "arrow-right-1", iconSize = "12px") => {
    return roundIcon ? (
      <RoundIcon
        name={iconName}
        size={iconSize}
        color={iconColor}
        bgColor={iconBgColor}
        className={cx("u-m-l--200", iconModifier)}
      />
    ) : (
      <Icon
        name={iconName}
        size={iconSize}
        color={iconColor}
        className={cx("u-m-l--200", iconModifier)}
      />
    );
  };

  return (
    <Link
      to={link}
      className={cx(
        "c-button",
        buttonModifier && `c-button--${buttonModifier}`,
        className,
      )}
      {...restProps}
    >
      {iconBefore ? iconComponent(iconBeforeName, iconBeforeSize) : null}
      {title}
      {iconAfter ? iconComponent(iconAfterName, iconAfterSize) : null}
    </Link>
  );
}
