import { H } from "@jfrk/react-heading-levels";
import React from "react";

import "./ImageModule.scss";
import { FluidImage } from "../Image";

export default function ImageModule({
  title,
  titleProps: { ...titleRestProps } = {},
  ...restProps
}) {
  return (
    <>
      {title && <H {...titleRestProps}>{title}</H>}
      <FluidImage {...restProps} />
    </>
  );
}
