import { Section } from "@jfrk/react-heading-levels";
import cx from "classnames";
import React from "react";

import { isEventRepeatable } from "../../hooks/events";
import CardIndex from "../Card/CardIndex";
import CardIndexEvent from "../Card/CardIndexEvent";
import CardList from "../Card/CardList";
import CardListEvent from "../Card/CardListEvent";
import SectionHeader from "../SectionHeader/SectionHeader";

export default function PostsModuleIndex({
  title,
  dataSource,
  items,
  // ...restProps
}) {
  const itemsToSliceFrom = items.length == 3 ? 3 : 2;
  const itemsToSliceTo =
    dataSource.postsCount >= 0 ? dataSource.postsCount : items.length;

  const itemsCard = items.slice(0, itemsToSliceFrom);
  const itemsList = items.slice(itemsToSliceFrom, itemsToSliceTo);

  const MaybeSection = title ? Section : React.Fragment;

  return (
    <>
      {title && (
        <SectionHeader
          title={title}
          withBorder={true}
          sectionHeader={
            dataSource.archiveLink && dataSource.postsDataPostType
              ? {
                  link: {
                    url: dataSource.postsDataPostType.url,
                    text: dataSource.postsDataPostType.labels.allItems,
                  },
                }
              : null
          }
        />
      )}
      <MaybeSection>
        <div
          className={cx(
            "c-card-group",
            items.length >= 3 ? "c-card-group--third" : "c-card-group--half",
          )}
        >
          {itemsCard.map((item, index) => {
            switch (item.postTypeInfo && item.postTypeInfo.name) {
              case "event":
                return (
                  <CardIndexEvent
                    key={index}
                    title={item.title}
                    date={item.date}
                    excerpt={item.excerpt}
                    url={item.url}
                    image={item.image}
                    repeatableEvent={isEventRepeatable(item)}
                  />
                );
              case "input":
                return (
                  <CardIndex
                    key={index}
                    title={item.title}
                    date={item.date}
                    content={item.content && item.content}
                    url={item.url}
                    image={item.image}
                  />
                );
              default:
                return (
                  <CardIndex
                    key={index}
                    title={item.title}
                    date={item.date}
                    excerpt={item.excerpt}
                    url={item.url}
                    image={item.image}
                  />
                );
            }
          })}
          {itemsList && (
            <div className="">
              {itemsList.map((item, index) => {
                switch (item.postTypeInfo && item.postTypeInfo.name) {
                  case "event":
                    return (
                      <CardListEvent
                        key={index}
                        title={item.title}
                        date={item.date}
                        url={item.url}
                      />
                    );
                  case "input":
                    return (
                      <CardList
                        key={index}
                        title={item.title}
                        excerpt={item.excerpt}
                        url={item.url}
                      />
                    );
                  default:
                    return (
                      <CardList
                        key={index}
                        title={item.title}
                        date={item.date}
                        url={item.url}
                      />
                    );
                }
              })}
            </div>
          )}
        </div>
      </MaybeSection>
    </>
  );
}
