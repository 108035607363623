import { useStaticQuery, graphql } from "gatsby";

export default function usePages() {
  return useStaticQuery(graphql`
    query PageTree {
      wp {
        pages(
          where: { parent: null, orderby: { field: MENU_ORDER, order: ASC } }
          first: 100000
        ) {
          nodes {
            id
            parentId
            title(format: RAW)
            label
            isFrontPage
            featuredImage {
              databaseId
              src: sourceUrl(size: PROMO_CARD_SMALL)
              srcSet(size: PROMO_CARD_LARGE)
              width(size: PROMO_CARD_LARGE)
              height(size: PROMO_CARD_LARGE)
              altText
            }
            pageTemplate
            uri
            url: uri
            showInMenu
            themeColor
            description
            icon
            hero {
              heroImage {
                base64: base64Uri
                databaseId
                src: sourceUrl(size: PROMO_CARD_SMALL)
                srcSet(size: PROMO_CARD_LARGE)
                width(size: PROMO_CARD_LARGE)
                height(size: PROMO_CARD_LARGE)
                altText
              }
              text
            }
            articleHeader {
              preambleImage {
                base64: base64Uri
                databaseId
                src: sourceUrl(size: MEDIUM)
                srcSet(size: MEDIUM)
                width(size: MEDIUM)
                height(size: MEDIUM)
                altText
                mimeType
                fileContent
              }
            }
          }
        }
      }
    }
  `).wp.pages.nodes;
}
