import { css } from "@emotion/react";
import cx from "classnames";
import React from "react";

import useID from "../../hooks/id";
import usePageContext from "../../hooks/pageContext";
import { getChildren } from "../../utils/pageTree";
import { RoundIcon } from "../Icon";
import Icon from "../Icon/Icon";
import Link from "../Link/Link";

import "./MobileNavigation.scss";

export default function MobileNavigationMenuItem({
  item,
  expandedItems,
  allPages,
  onItemExpand,
  onItemCollapse,
}) {
  const id = useID();
  const { page: currentPage } = usePageContext();
  const childPages = getChildren(allPages, item.id).filter(
    (page) => page.showInMenu,
  );
  let isExpandable = childPages.length > 0;
  let isExpanded = isExpandable ? expandedItems.includes(item.id) : null;

  return (
    <li
      className={cx(
        "c-flyout__nav-list",
        currentPage && currentPage.id === item.id && "is-current",
        isExpandable && "has-children",
        item.themeColor && `theme--${item.themeColor}`,
      )}
    >
      <div className="c-flyout__nav-list-item">
        <Link className="c-flyout__nav-list-item-link" to={item.url}>
          {item.pageTemplate === "landing-page.php" && (
            <RoundIcon
              name={item.icon}
              className="c-flyout__nav-list-item-icon"
              size="1.75rem"
              bgColor={`var(--theme-${item.themeColor})`}
            />
          )}
          {item.label}
        </Link>
        {isExpandable ? (
          <button
            className={cx(
              "c-flyout__nav-list-button",
              isExpanded && "c-flyout__nav-list-button--expanded",
            )}
            onClick={() => {
              if (isExpanded) {
                onItemCollapse(item.id);
              } else {
                onItemExpand(item.id);
              }
            }}
            type="button"
            aria-controls={id(`mobile-navigation-menu-item-children`)}
            aria-expanded={isExpanded}
          >
            <Icon name={isExpanded ? "minus" : "plus"} />
          </button>
        ) : null}
      </div>

      {isExpandable ? (
        <ul
          css={css`
            display: ${!isExpanded && "none"};
          `}
          className={cx(!isExpanded && "hide-children", "c-flyout__nav-child")}
          id={id(`mobile-navigation-menu-item-children`)}
        >
          {isExpanded
            ? childPages.map((page, index) => {
                return (
                  <MobileNavigationMenuItem
                    key={index}
                    item={page}
                    expandedItems={expandedItems}
                    allPages={allPages}
                    onItemExpand={onItemExpand}
                    onItemCollapse={onItemCollapse}
                  />
                );
              })
            : null}
        </ul>
      ) : null}
    </li>
  );
}
